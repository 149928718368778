<template>
  <home-customers v-if="$store.state.user.type == 'customer'" />
  <home-staffs v-else />
</template>

<script>
import HomeStaffs from '@/views/HomeStaffs.vue';
import HomeCustomers from '@/views/HomeCustomers.vue';

export default {
  components: {
    HomeStaffs,
    HomeCustomers,
  },
}
</script>