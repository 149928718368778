<template>
  <div class="home">
    <div class="pa-4">
      
      <h2>スタッフ お知らせ</h2>
      <v-container>
        <v-row>
          <v-col v-for="item in $store.state.userNotifications" :key="item.id" cols="12" md="6">
            <notification-banner
              :item="item"
            />
          </v-col>
          <v-col v-for="item in $store.state.orgNotifications" :key="item.id" cols="12" md="6">
            <notification-banner
              :item="item"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

import NotificationBanner from '@/components/NotificationBanner';

export default {
  name: 'home',
  components: {
    NotificationBanner
  },
  methods: {
  },
  mounted() {
    console.log(this.$notifications.subscribe());
  },
  beforeDestroy() {
    console.log(this.$notifications.unsubscribe());
  }
}
</script>

<style scoped>
h1 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

h2 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

.tech-cp-2207-title {
  font-weight: bold;
  margin: 0.75rem 0;
  line-height: 1.5rem;
  text-align: justify;
}

.tech-cp-2207-created {
  color: gray;
}
</style>

<style lang="scss">
@import '@/sass/variables.scss';
</style>


