<template>
  <v-dialog
    content-class="customer-tutorial-dialog"
    persistent
    v-model="dialogModel"
    max-width="840px"
  >
    <v-card flat>
      <v-carousel
        :cycle="cycle"
        height="auto"
        interval="6000"
        show-arrows-on-hover
        hide-delimiter-background
        light
        style="min-height:100%"
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
        >
          <v-img
            eager
            :src="item"
            contain
            height="510"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-card-actions>
        <v-btn
          :class="cycle ? 'primary--text' : 'warning--text'"
          style="margin-top: 1.5px;"
          large
          icon
          @click="toggleCycle()"
        >
          <v-icon v-if="!cycle">mdi-toggle-switch-outline</v-icon>
          <v-icon v-else>mdi-toggle-switch-off-outline</v-icon>
        </v-btn>
        <div v-if="cycle" class="primary--text text-body-2">自動スクロール中</div>
        <div v-else class="warning--text text-body-2">自動スクロール停止中</div>
        <div class="flex-grow-1"></div>
        <v-btn color="primary darken-1" text @click="closeDialog">チュートリアルを閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CustomerTutorialDialog',
  components: {
  },
  props: {
    dialogModel: Boolean,
  },
  mixins: [
  ],
  data() {
    return {
      cycle: true,
      items: [
        require('@/assets/home_customers/tutorials/tutorial-01.png'),
        require('@/assets/home_customers/tutorials/tutorial-02.png'),
        require('@/assets/home_customers/tutorials/tutorial-03.png'),
        require('@/assets/home_customers/tutorials/tutorial-04.png'),
        require('@/assets/home_customers/tutorials/tutorial-05.png'),
      ],
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
    toggleCycle() {
      this.cycle = !this.cycle;
    },
  },
  mounted() {
  },
}
</script>

<style>
  .customer-tutorial-dialog .v-carousel__controls {
    position: relative;
    padding-bottom: 20px;
  }

  .customer-tutorial-dialog.v-dialog {
    box-shadow: none;
    border-radius: 14px;
  }

  .customer-tutorial-dialog .v-window__next .v-btn__content,
  .customer-tutorial-dialog .v-window__prev .v-btn__content {
    color: #ffffff;
  }

  .customer-tutorial-dialog .v-card__actions {
    margin-top: -26px;
    padding: 10px 25px !important;
  }

  .customer-tutorial-dialog .v-card__actions .v-btn {
    z-index: 10;
  }

</style>

