<template>
  <div class="home" style="background-color: #FAFAFA;">

    <!-- メインタイトル -->
    <div style="background-color: #102F8F;">
      <v-container fluid>
        <v-row class="pt-7 pb-6">
          <v-col cols="12">
            <v-img
              :src="require('@/assets/home_customers/title-main.svg')"
              contain
              max-height="70"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- デバイスのご使用 -->
    <div style="background-color: #E6F0FA;">
      <v-container fluid style="max-width: 1160px;">
        <v-row class="pt-6 pb-3">
          <v-col cols="12">
            <v-img
              :src="require('@/assets/home_customers/title-use-devices.svg')"
              contain
              height="30"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="pl-5 pr-5 pb-6">

          <v-col cols="12" sm="6" class="device-use-parts">
            <v-img
              class="device-use-imgs"
              :src="require('@/assets/home_customers/button-sokutei.svg')"
              contain
              max-width="480"
              @click="$router.replace('/patient-register')"
            ></v-img>
          </v-col>

          <v-col cols="12" sm="6" class="device-use-parts">
            <v-img
              class="device-use-imgs"
              :src="require('@/assets/home_customers/button-kaiseki.svg')"
              contain
              max-width="480"
              @click="$router.replace('/analyses')"
            ></v-img>
          </v-col>

        </v-row>
      </v-container>
    </div>

    <div>
      <v-container fluid style="max-width: 1160px;">
        <v-row class="pl-5 pr-5">

          <!-- 各種ボタン -->
          <v-col cols="12" sm="6">
            <!-- タイトル画像 -->
            <v-row>
              <v-col cols="12" class="pre-use-parts pt-9 pb-5">
                <v-img
                  :src="require('@/assets/home_customers/title-pre-use-system.svg')"
                  contain
                  height="30"
                ></v-img>
              </v-col>
            </v-row>
            <!-- コンポーネント -->
            <v-row>
              <v-col cols="12" class="pre-use-parts">
                <v-img
                  class="pre-use-imgs"
                  :src="require('@/assets/home_customers/button-stuff.svg')"
                  contain
                  max-width="480"
                  @click="$router.replace('/staffs')"
                ></v-img>
              </v-col>

              <v-col cols="12" class="pre-use-parts">
                <v-img
                  class="pre-use-imgs"
                  :src="require('@/assets/home_customers/button-tutorial.svg')"
                  contain
                  max-width="480"
                  @click="dialogModel = true"
                ></v-img>
              </v-col>

              <v-col cols="12" class="pre-use-parts">
                <v-img
                  class="pre-use-imgs"
                  :src="require('@/assets/home_customers/button-manual.svg')"
                  contain
                  max-width="480"
                  @click="$router.replace('/help')"
                ></v-img>
              </v-col>
            </v-row>  <!-- コンポーネント -->
          </v-col>  <!-- 各種ボタン -->

          <!-- システムからのお知らせ -->
          <v-col cols="12" sm="6">
            <!-- タイトル画像 -->
            <v-row>
              <v-col cols="12" class="pre-use-parts pt-9 pb-5">
                <v-img
                  :src="require('@/assets/home_customers/title-notice-system.svg')"
                  contain
                  height="30"
                ></v-img>
              </v-col>
            </v-row>
            <!-- コンポーネント -->
            <v-row
              style="max-height: 900px"
              class="overflow-y-auto justify-center"
            >
              <!-- 22/07 技師解析CPに対する一時的な対応 ↓↓↓↓↓↓↓↓↓↓ -->
              <div v-if="$store.state.configs.is_tech_cp_2207 === true">
              </div>
              <!-- ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ -->

              <v-col v-for="item in items" :key="item.id" cols="12" class="pre-use-parts">
                <v-banner
                  :sticky="true"
                  :single-line="false"
                  elevation="0"
                  width="480"
                  color="#ffffff"
                >
                  <v-avatar
                    slot="icon"
                    :color="item.icon_color ? item.icon_color : 'green'"
                    size="40"
                  >
                    <v-icon color="white">
                      {{item.icon_type ? item.icon_type : 'assignment'}}
                    </v-icon>
                  </v-avatar>
                  <div class="created">掲載日：{{ t2s(item.start_date) }}</div>
                  <h3 class="title">{{ item.title }}</h3>
                  <div style="white-space:pre-wrap; word-break:break-all;">{{ item.message }}</div>
                </v-banner>
              </v-col>

              <v-col v-if="
                  items.length === 0
                  && (
                    !('is_tech_cp_2207' in $store.state.configs)
                    || $store.state.configs.is_tech_cp_2207 !== true
                  )
                "
                cols="12"
                class="pre-use-parts"
              >
                <v-banner
                  elevation="0"
                  width="480"
                  color="#ffffff"
                >
                  <div style="white-space:pre-wrap; word-break:break-all;">お知らせはありません。</div>
                </v-banner>
              </v-col>
            </v-row>  <!-- コンポーネント -->
          </v-col> <!-- システムからのお知らせ -->
        </v-row>

        <!-- デバイスについてのお知らせ -->
        <v-row class="pl-5 pr-5">
          <v-col col="12">
            <!-- タイトル画像 -->
            <v-row>
              <v-col cols="12" class="pre-use-parts pt-9 pb-5">
                <v-img
                  :src="require('@/assets/home_customers/title-notice-device.svg')"
                  contain
                  height="30"
                ></v-img>
              </v-col>
            </v-row>
            <!-- コンポーネント -->
            <v-row
              style="max-height: 800px"
              class="overflow-y-auto mb-4"
            >
              <v-col v-for="item in $store.state.userNotifications" :key="item.id" cols="12" sm="6" class="pre-use-parts">
                <notification-banner
                  :item="item"
                  width="480"
                  color="#ffffff"
                  elevation="0"
                  mobile-breakpoint="3000000"
                  rounded="14"
                />
              </v-col>

              <v-col v-for="item in $store.state.orgNotifications" :key="item.id" cols="12" sm="6" class="pre-use-parts">
                <notification-banner
                  :item="item"
                  width="480"
                  color="#ffffff"
                  elevation="0"
                  mobile-breakpoint="3000000"
                  rounded="14"
                />
              </v-col>

              <v-col v-if="
                  $store.state.orgNotifications.length === 0
                  && $store.state.userNotifications.length === 0
                "
                cols="12" sm="6"
                class="pre-use-parts"
              >
                <v-banner
                  elevation="0"
                  width="480"
                  color="#ffffff"
                >
                  <div style="white-space:pre-wrap; word-break:break-all;">お知らせはありません。</div>
                </v-banner>
              </v-col>
            </v-row>  <!-- コンポーネント -->
          </v-col>
        </v-row>  <!-- デバイスについてのお知らせ -->

      </v-container>
    </div>

    <!-- チュートリアル -->
    <customer-tutorial-dialog
      :dialogModel="dialogModel"
      @closed="onDialogClosed"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import NotificationBanner from '@/components/NotificationBanner';
import CustomerTutorialDialog from '@/components/CustomerTutorialDialog';

let unsubscribeItems = null;

export default {
  name: 'home',
  components: {
    NotificationBanner,
    CustomerTutorialDialog,
  },
  data() {
    return {
      dialogModel: this.$route.params.isFirstLogin ? true : false,
      items: [],
      offsetTop: 0,
    }
  },
  computed: {
  },
  methods: {
    onDialogClosed() {
      this.dialogModel = false;
    },
    subscribeItems() {
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();

      let query = this.$db.collection('notifications_management')
           .where('display_position', '!=', 0);

      unsubscribeItems = query.onSnapshot(async (querySnapshot) => {
        this.items = [];
        const now = new Date();
        querySnapshot.forEach(async (doc) => {
          let data = doc.data();
          if (data.start_date) {
            // 開始日時のチェック
            const startDate = new Date(data.start_date.seconds * 1000);
            if (now < startDate) return;
          }
          if (data.end_date) {
            // 終了日時のチェック
            const endDate = new Date(data.end_date.seconds * 1000);
            endDate.setDate(endDate.getDate() + 1);
            if (endDate <= now) return;
          }
          if (data.is_select_all_customers === false) {
            // 全ての医療機関ではない場合、自身の組織が指定されていない場合はスキップ
            if (!data.selected_customers.includes(this.$store.state.user.org_id)) return;
          }
          data.id = doc.id;
          this.items.push(data);
        });
        // 表示位置の降順に並べ替え
        this.items.sort((a, b) => {
          return a.display_position - b.display_position;
        });
      }, (e) => {
        console.log('error', e);
        this.items = [];
      });
    },
    t2s (t) {
      return t ? dateformat(new Date(t.seconds * 1000), 'yyyy/mm/dd'): '';
    },
  },
  mounted() {
    this.$notifications.subscribe();
    this.subscribeItems();
  },
  beforeDestroy() {
    this.$notifications.unsubscribe();
    if (unsubscribeItems) unsubscribeItems();
  }
}
</script>

<style scoped>
h1 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

h2 {
  margin: 1em;
  text-align: center;
  font-family:Arial, Helvetica, sans-serif
}

.tech-cp-2207-title {
  font-weight: bold;
  margin: 0.75rem 0;
  line-height: 1.5rem;
  text-align: justify;
}

.tech-cp-2207-created {
  color: gray;
}

.device-use-imgs {
  transition: box-shadow 0.2s;
  border-radius: 14px;
}

.device-use-imgs:hover {
  box-shadow: rgb(12 47 143 / 40%) 5px 5px 7px 1px;
}

.pre-use-imgs {
  transition: box-shadow 0.2s;
  border-radius: 14px;
}

.pre-use-imgs:hover {
  box-shadow: rgb(153 198 78 / 40%) 5px 5px 7px 1px;
}

.device-use-parts {
  display: flex;
  justify-content: center;
}

.pre-use-parts {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-sheet.v-banner {
  border-radius: 14px;
}

.pre-use-parts .title {
  font-weight: bold;
  margin: 0.75rem 0;
  line-height: 1.5rem;
  text-align: justify;
}
.pre-use-parts .created {
  color: gray;
}

</style>

<style lang="scss">
@import '@/sass/variables.scss';

.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper {
  border-bottom: none !important;
}



</style>
