<template>
   <v-banner
    :sticky="true"
    :single-line="false"
    :elevation="elevation ? elevation : 3"
    :width="width"
    :color="color"
    :mobile-breakpoint="mobileBreakpoint"
  >
    <v-avatar
      slot="icon"
      :color="item.color ? item.color : 'green'"
      size="40"
    >
      <v-icon
        :color="item.icon_color ? item.icon_color : 'white'"
      >
        {{item.icon ? item.icon : 'assignment'}}
      </v-icon>
    </v-avatar>
    <div class="created">{{item.created}}</div>
    <h3 class="title">{{item.title}}</h3>
    <div style="white-space:pre-wrap; word-break:break-all;">{{ item.message }}</div>
    <template v-slot:actions >
      <v-btn
        text 
        color="deep-purple accent-4"
        @click="openNotification"
      >
        確認
      </v-btn>      
    </template>
  </v-banner>
</template>

<script>
export default {
  props: {
    item: Object,
    width: String,
    color: String,
    elevation: String,
    mobileBreakpoint: String,
    rounded: String,
  },
  methods: {
    openNotification() {
      console.log('openNotification', this.item);
      // ユーザーとタイムスタンプを記録
      this.$notifications.confirm(this.item.id)
      // コピー
      let link = Object.assign({}, this.item.link);
      // linkにnotification_idを含めて、後ほど削除可能にする。
      link.params.notification_id = this.item.id;
      this.$router.push(link); 
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: bold;
  margin: 0.75rem 0;
  line-height: 1.5rem;
  text-align: justify;
}
.created {
  color: gray;
}
</style>