import { render, staticRenderFns } from "./HomeCustomers.vue?vue&type=template&id=22d89dd6&scoped=true&"
import script from "./HomeCustomers.vue?vue&type=script&lang=js&"
export * from "./HomeCustomers.vue?vue&type=script&lang=js&"
import style0 from "./HomeCustomers.vue?vue&type=style&index=0&id=22d89dd6&scoped=true&lang=css&"
import style1 from "./HomeCustomers.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d89dd6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBanner,VCol,VContainer,VIcon,VImg,VRow})
